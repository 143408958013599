$(document).ready(function () {
  $("#filterInput").on("input", function () {
    $('a[data-toggle="collapse"][aria-expanded="false"]').click();
    var value = $(this).val().toLowerCase();
    $(".filterable-card").filter(function () {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
    });

    $(".filterable-group").filter(function() {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
    });
  });
});
