//
// fancybox.js
//

(function() {
  function globalOptions() {
    $.fancybox.defaults.image.preload = false;
    $.fancybox.defaults.toolbar = false;
    $.fancybox.defaults.clickContent = false;
    $.fancybox.defaults.smallBtn = true;
  }

  if (jQuery().fancybox) {
    globalOptions();
  }
})();
