$(document).ready(function () {
  function enableForm() {
    $('.emailSubscriptionForm .submit').prop("disabled", false);
    $('.emailSubscriptionForm .submit .text').removeClass('d-none')
    $('.emailSubscriptionForm .submit .loading').addClass('d-none')

    $('.emailSubscriptionForm .email').prop("disabled", false);
  }

  function disableForm() {
    $('.emailSubscriptionForm .submit').prop("disabled", true);
    $('.emailSubscriptionForm .submit .text').addClass('d-none')
    $('.emailSubscriptionForm .submit .loading').removeClass('d-none')

    $('.emailSubscriptionForm .email').prop("disabled", true);
  }

  $('.emailSubscriptionForm').on('submit', function (e) {
    e.preventDefault();
    gtag('event', 'subscribeToNewsletter', {
      'event_category': 'Newsletter',
    });

    const formData = $(this).serialize();
    const action = e.currentTarget['action'];
    disableForm();
    $.post(action, formData, function (data) {
      enableForm();
      $('#emailSubscriptionConfirmation').modal();
    }).fail(function (response) {
      enableForm();
      alert('Something went wrong!');
    });

    return false;
  });
});
