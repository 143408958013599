$(".review_read_more").on('click', function(event) {
  let reviewName = $(this).closest('.card').find('.review_name').html();
  let reviewDate = $(this).closest('.card').find('.review_date').html();
  let reviewContent = $(this).closest('.card')
    .find('.review_content').data('reviewContent');
  
  $('#reviewModal .review_name').html(reviewName);
  $('#reviewModal .review_date').html(reviewDate);
  $('#reviewModal .review_content').html(reviewContent);
  $('#reviewModal').modal();
});
