$(function() {
  $('#stickyHelpButton').on('click', function(e) {
    e.preventDefault();
    const existingScript = document.getElementById('ze-snippet');

    if (!existingScript) {
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = 'ze-snippet';
      script.async = true;
      script.src = 'https://static.zdassets.com/ekr/snippet.js?key=1a627125-d64d-41c4-b92e-6f291c5e3b43';
      document.getElementsByTagName('body')[0].appendChild(script);
      script.onload = () => {
        zE('webWidget', 'open');
        zE('webWidget:on', 'close', function () {
          zE('webWidget', 'hide');
        });
      }
    } else {
      zE('webWidget', 'show');
      zE('webWidget', 'open');
    }
  });
})
