// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'jquery'
import 'aos'
import 'popper.js'
import 'bootstrap'
import '@fancyapps/fancybox'
import 'countup.js'
import 'flickity'
import 'flickity-fade'
import 'highlightjs'
import 'jarallax'
import 'smooth-scroll'
import 'typed.js'
import 'anchor-js'
import 'lazysizes'

import './goodkit/aos'
import './goodkit/card-stack'
import './goodkit/countup'
import './goodkit/fancybox'
import './goodkit/flickity'
import './goodkit/highlight'
import './goodkit/modal'
import './goodkit/password'
import './goodkit/popovers'
import './goodkit/price'
import './goodkit/prices'
import './goodkit/smooth-scroll'
import './goodkit/svgshim'
import './goodkit/table'
import './goodkit/table-features'
import './goodkit/tooltips'
import './goodkit/typed'
import './anchored'

import './email_subscription_form'
import './filter_cards'
import './reviews'
import './analytics'
import './zendesk'