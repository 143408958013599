$(document).ready(function () {
  $('.discord-button').on('click', function(e) {
    e.preventDefault();
    var url = $(this).attr('href');

    gtag('event', 'joinDiscord', {
      'event_category': 'Community',
      'event_callback': function () {
        document.location = url;
      }
    });
  });
});
